import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

export interface State {
  isOnline: boolean;
  _hasHydrated: boolean;
}

interface Actions {
  setHasHydrated: (state: boolean) => void;
}

const useZustandStore = create<State & Actions>()(
  devtools(
    persist(
      (set, get): State & Actions => ({
        isOnline: true,
        _hasHydrated: false,

        setHasHydrated: (state) => {
          set({
            _hasHydrated: state,
          });
        },
      }),
      {
        name: 'zacharie-zustand-store',
        version: 1,
        // storage: createJSONStorage(() => storage),
        storage: createJSONStorage(() => window.localStorage),
        onRehydrateStorage: (state) => {
          return () => state.setHasHydrated(true);
        },
        partialize: (state) =>
          Object.fromEntries(Object.entries(state).filter(([key]) => !['feiDone'].includes(key))),
      },
    ),
  ),
);

export default useZustandStore;

export async function syncData() {
  const isOnline = useZustandStore.getState().isOnline;
  if (!isOnline) {
    console.log('not syncing data because not online');
    return;
  }
  console.log('finito');
  // return syncFeis()
  //   .then(() => console.log('synced feis finito'))
  //   .then(syncCarcasses)
  //   .then(() => console.log('synced carcasses finito'))
  //   .then(syncFeiIntermediaires)
  //   .then(() => console.log('synced intermediaires finito'))
  //   .then(syncCarcassesIntermediaires)
  //   .then(() => console.log('synced carcasses intermediaires finito'))
  //   .then(syncLogs)
  //   .then(() => console.log('synced logs finito'))
  //   .then(() => console.log('synced data finito'))
  //   .catch((error) => {
  //     capture(error);
  //   });
}
