import { NavLink } from 'react-router';
import { NavItem } from '../types';
import { HomeIcon, UserIcon, BookOpenIcon, PhoneIcon } from '@heroicons/react/24/outline';

const navItems: NavItem[] = [
  { name: 'Home', path: '/', icon: HomeIcon },
  { name: 'About', path: '/about', icon: UserIcon },
  { name: 'Services', path: '/services', icon: BookOpenIcon },
  { name: 'Contact', path: '/contact', icon: PhoneIcon },
];

export const Navigation = () => {
  return (
    <>
      {/* Desktop Navigation */}
      <nav className="hidden md:block fixed top-0 left-0 right-0 bg-yellow-50 shadow-md z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0">
              <span className="text-2xl font-bold text-yellow-600">Life Coach</span>
            </div>
            <div className="flex space-x-8">
              {navItems.map((item) => (
                <NavLink
                  key={item.path}
                  to={item.path}
                  className={({ isActive }) =>
                    `text-gray-700 hover:text-yellow-600 px-3 py-2 rounded-md text-sm font-medium transition-colors
                    ${isActive ? 'text-yellow-600' : ''}`
                  }
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <nav className="md:hidden fixed bottom-0 left-0 right-0 bg-yellow-50 shadow-lg z-50">
        <div className="grid grid-cols-4 h-16">
          {navItems.map((item) => {
            const Icon = item.icon;
            return (
              <NavLink
                key={item.path}
                to={item.path}
                className={({ isActive }) =>
                  `flex flex-col items-center justify-center space-y-1
                  ${isActive ? 'text-yellow-600' : 'text-gray-600'}`
                }
              >
                <Icon className="h-6 w-6" />
                <span className="text-xs">{item.name}</span>
              </NavLink>
            );
          })}
        </div>
      </nav>
    </>
  );
};
