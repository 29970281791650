import { Routes, Route } from 'react-router';
import { Navigation } from './components/Navigation';
import { Home } from './routes/Home';

import LandingPage from './routes/landing';
// import Connexion from './routes/connexion';
// import { useEffect, type ReactElement } from 'react';
// import Chargement from './components/Chargement';
import OfflineMode from './components/OfflineMode';
import * as Sentry from '@sentry/react';
// import { capture } from './services/sentry';
// import { UserRoles } from '@prisma/client';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
// 1 onglet QUIZZ,
// 1 onglet HABIT TRACKER GRATUIT (avec prompt personnalisé!!!),
// 1 onglet Recettes IG Bas 😃
// et 1 onglet ARTICLES SANT’E
function App() {
  return (
    <>
      <OfflineMode />
      <div className="min-h-screen bg-gray-50">
        <Navigation />
        <main className="pb-16 md:pb-0">
          <SentryRoutes>
            <Route index path="/" element={<Home />} />
            <Route path="/blog" element={<div className="pt-20">Blog</div>} />
            <Route path="/services" element={<div className="pt-20">Services Page</div>} />
            <Route path="/contact" element={<div className="pt-20">Contact Page</div>} />
            {/* <Route index element={<LandingPage />} /> */}
          </SentryRoutes>
        </main>
      </div>
    </>
  );
}

// function RestrictedRoute({
//   children,
//   id,
//   roles = [],
// }: {
//   children: ReactElement;
//   id: string;
//   roles?: UserRoles[];
// }) {
//   const user = getMostFreshUser('RestrictedRoute ' + id);
//   const navigate = useNavigate();

//   // console.log(id, 'restricted route user', user);

//   useEffect(() => {
//     if (!user?.id) {
//       navigate('/app/connexion?type=compte-existant');
//     }
//     if (roles.length > 0 && !roles.some((role) => user?.roles.includes(role))) {
//       navigate('/app/connexion?type=compte-existant');
//     }
//   }, [user, navigate, roles]);

//   if (!user?.id) {
//     return <Chargement />;
//   }

//   return children;
// }

// function TestSentry() {
//   useEffect(() => {
//     capture('Test Sentry');
//   }, []);
//   return <div>Test Sentry</div>;
// }

export default App;
